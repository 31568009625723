import styled from 'styled-components'

type TProps = {
  src: string,
  alt: string
}

export const TokenImage = styled.img<TProps>`
  max-width: 343px;
  max-height: 343px;
  object-fit: cover;
  display: block;
  border-radius: 8px;
  width: 40vh;
  height: 40vh;
`