import { FC } from 'react'
import {
  Content,
  Title,
  Description,
  ApplicationStore,
  ApplicationStoreLink,
  LedgerIcon
} from './styled-components'
import AppStoreLogo from 'images/app-store.png'
import GooglePlayLogo from 'images/google-play.png'
import { defineSystem, defineApplicationUrl } from 'helpers'
import { TSystem } from 'types'
import TProps from './types'
import Icons from 'icons'


const defineStoreLogo = () => {
  const system: TSystem = defineSystem()
  switch (system) {
    case 'android':
      return <ApplicationStore src={GooglePlayLogo} />
    case 'ios':
      return <ApplicationStore src={AppStoreLogo} />
    default:
      return <LedgerIcon>
      <Icons.LedgerLogo />
    </LedgerIcon>
  }
}

const DownloadApplication: FC<TProps> = ({ className, isClaimed }) => {
  return <Content className={className}>
  <Title>THE MOST SECURE WALLET</Title>
  <Description>
    Download Ledger Live app
  </Description>
  <ApplicationStoreLink target='_blank' href={defineApplicationUrl()}>
    {defineStoreLogo()}
  </ApplicationStoreLink>
</Content>
} 

export default DownloadApplication