import React from 'react'
import { Provider } from 'react-redux'
import RouterProvider from './router-provider'
import store from 'data/store';
import { Container } from './styled-components'

class Application extends React.Component {
  render () {
    return <Container>
      <Provider store={store}>
        <RouterProvider />
      </Provider>
    </Container>
  }
}
export default Application
