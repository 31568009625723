import React, { FC } from 'react'
import { Image, Title, Subtitle, Container } from './styled-components'
import Page from '../page'
import SadBlack from 'images/sad-black.png'
import SadWhite from 'images/sad-white.png'
import { getHashVariables } from 'helpers'

const AllLinksClaimedPage: FC = () => {
  const { theme } = getHashVariables(window.location.href)
  const image = theme === 'light' ? SadBlack : SadWhite
  return <Page>
    <Container>
      <Image src={image} />
      <Title>All NFTs have been claimed</Title>
      <Subtitle>Stay tuned to grab yours next time</Subtitle>
    </Container>    
  </Page>
}

export default AllLinksClaimedPage