import { FC, useEffect } from 'react'
import { getHashVariables, defineRedirectUrl } from 'helpers'
import { useHistory } from 'react-router-dom'

const HomePage: FC = () => {
  const history = useHistory()
  useEffect(() => {
    const urlVariables = getHashVariables(window.location.href)
    const {
      linkParams,
      code,
      autoClaim,
      redirectToOnboarding,
      theme = 'dark'
    } = urlVariables
    const redirectUrl = defineRedirectUrl({
      linkParams,
      code,
      autoClaim,
      redirectToOnboarding,
      theme
    })
    // const link = `/receive?`

    if (!redirectUrl) {
      return alert('deep link is not valid. check params')
    }
    history.push(redirectUrl)
    
  }, [])
  return null
}

export default HomePage