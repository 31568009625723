const themes = {
  dark: {
    primaryTextColor: '#FFF', // main color of text
    secondaryTextColor: '#000', // secondary color of text
    additionalTextColor: '#C3C3C3', // secondary color of text
    extraTextColor: '#6A6A6A', // secondary color of text
    disabledTextColor: '#E0E0E0', // disabled color of text


    dangerTextColor: '#FF2C2C', // danger color of text
    noteTextColor: '#D4CCFF', // note color of text
    primaryBorderColor: '#E4E4E4',
    secondaryBorderColor: '#FFF',
    additionalBorderColor: '#272727',
    primaryColor: '#F7F8FC', // main color of bg
    secondaryColor: '#363740',
    blankColor: '#131214',
    menuItemActive: '#3E4049',
    primaryHighlightColor: '#2B32EA',
    loaderPrimaryColor: '#000',
    widgetColor: '#FFF',
    loaderColor: '#BBB0FF',
    linkColor: '#BBB0FF',
  
    buttonDefaultColor: '#FFF',
    buttonDisabledColor: '#272727',
    buttonHoverColor: '#4047EC',
    buttonActiveColor: '#2229DF',
    buttonDisabledTextColor: '#C5C7CD',
    buttonBorderColor: '#565656',

    tagDefaultColor: '#DFE0EB',
    tagErrorColor: '#FF2C2C',
    tagInfoColor: '#2B32EA',
    tagSuccessColor: '#21F142',

    noteDefaultBgColor: '#343248',
    noteDefaultTextColor: '#D4CCFF',

    noteAttentionBgColor: '#272727',
    noteAttentionTextColor: '#C3C3C3',
    noteWarningTextColor: '#3E2909',
    toastBackgroundColor: '#2D2A3D',

    inputBgColor: '#272727',
    inputBorderColor: '#565656',
    inputBorderHoverColor: '#C3C3C3',
    inputPlaceholderColor: '#565656',

    ethereumLogoColor: '#0EBDCD',
    polygonLogoColor: '#0EBDCD',
  },
  light: {
    primaryTextColor: '#000', // main ccolor of text
    secondaryTextColor: '#FFF', // secondary color of text
    additionalTextColor: '#C3C3C3', // secondary color of text
    extraTextColor: '#6A6A6A', // secondary color of text
    disabledTextColor: '#DFE0EB', // disabled color of text
    dangerTextColor: '#FF2C2C', // danger color of text
    noteTextColor: '#D4CCFF', // note color of text
    primaryBorderColor: '#000',
    secondaryBorderColor: '#000',
    additionalBorderColor: '#272727',
    primaryColor: '#FFF', // main color of bg
    secondaryColor: '#363740', // secondary coplor of bg
    blankColor: '#FFF', // secondary coplor of bg
    menuItemActive: '#3E4049',
    primaryHighlightColor: '#2B32EA',
    loaderPrimaryColor: '#000',
    widgetColor: '#FFF',
    loaderColor: '#BBB0FF',
    linkColor: '#BBB0FF',

    buttonDefaultColor: '#000',
    buttonDisabledColor: '#f5f5f5',
    buttonHoverColor: '#4047EC',
    buttonActiveColor: '#2229DF',
    buttonDisabledTextColor: '#d6d6d6',
    buttonBorderColor: '#565656',

    tagDefaultColor: '#DFE0EB',
    tagErrorColor: '#FF2C2C',
    tagInfoColor: '#2B32EA',
    tagSuccessColor: '#21F142',

    noteDefaultBgColor: '#343248',
    noteDefaultTextColor: '#D4CCFF',

    noteAttentionBgColor: '#272727',
    noteAttentionTextColor: '#C3C3C3',
    noteWarningTextColor: '#3E2909',

    inputBgColor: '#272727',
    inputBorderColor: '#565656',
    inputBorderHoverColor: '#C3C3C3',
    inputPlaceholderColor: '#565656',

    ethereumLogoColor: '#0EBDCD',
    polygonLogoColor: '#0EBDCD',
    toastBackgroundColor: '#2D2A3D',
  }
}

export default themes