import { FC } from 'react'
import { RootState } from 'data/store'
import { connect } from 'react-redux'
import {
  defineExplorerURL,
  defineAccountsDeeplink,
  defineLedgerChain
} from 'helpers'
import { 
  Title,
  ButtonsContainer,
  ScreenButton,
  TokenImageSmall,
  TokenImageContainer,
  DoneIcon,
  Subtitle,
  DownloadApplicationStyled
} from './styled-components'

const mapStateToProps = ({
  drop: {
    hash,
    chainId,
    redirectToOnboarding
  },
  user: {
    isLedgerLive,
    address
  },
  token: {
    image,
    name
  }
}: RootState) => ({
  image,
  name,
  chainId,
  hash,
  isLedgerLive,
  address,
  redirectToOnboarding
})

type ReduxType = ReturnType<typeof mapStateToProps>

const AlreadyClaimed: FC<ReduxType> = ({
  image,
  name,
  chainId,
  hash,
  address,
  isLedgerLive,
  redirectToOnboarding
}) => {
  let chain = defineLedgerChain({ chainId: String(chainId) })
  return <>
    {image && <TokenImageContainer>
      <DoneIcon />
      <TokenImageSmall
        src={image}
        alt={name}
      />
    </TokenImageContainer>}
    <Title>NFT already claimed</Title>
    <Subtitle>This NFT has already been claimed. If you did that you can find it in your wallet.</Subtitle>
    <ButtonsContainer>
      {!redirectToOnboarding && isLedgerLive && address && <ScreenButton
        title='Go to my accounts'
        href={defineAccountsDeeplink({
          address,
          currency: chain
        })}
      />}
      {!redirectToOnboarding && chainId && hash && <ScreenButton
        title='View in explorer'
        appearance='inverted'
        target='_blank'
        href={`${defineExplorerURL(chainId)}/tx/${hash}`}
      />}
      {redirectToOnboarding && <ScreenButton
        title='Back to setup'
        href='ledgerlive://post-onboarding/nft-claimed?completed=true'
      />}
    </ButtonsContainer>
  </>
}

export default connect(mapStateToProps)(AlreadyClaimed)