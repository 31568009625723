import { FC } from 'react'
import { Title, Subtitle, ButtonStyled } from './styled-components'
import { connect } from 'react-redux'
import * as dropActions from 'data/store/reducers/drop/actions'
import { DropActions } from 'data/store/reducers/drop/types'
import { Dispatch } from 'redux'
import { RootState } from 'data/store'

const mapStateToProps = ({
  user: {
    isLedgerLive
  }
}: RootState) => ({
  isLedgerLive
})

const mapDispatcherToProps = (dispatch: Dispatch<DropActions>) => {
  return {
    resetToInitialPage: (isLedgerLive: boolean) => dispatch(
      dropActions.setStep(isLedgerLive ? 'initial' : 'set_connector')
    )
  }
}

type ReduxType = ReturnType<typeof mapDispatcherToProps> & ReturnType<typeof mapStateToProps>

const ErrorComponent: FC<ReduxType> = ({
  resetToInitialPage,
  isLedgerLive
}) => {
  return <>
    <Title>Seems you're offline</Title>
    <Subtitle>Please, try again</Subtitle>
    <ButtonStyled onClick={() => resetToInitialPage(isLedgerLive)}>Retry</ButtonStyled>
  </>
}

export default connect(mapStateToProps, mapDispatcherToProps)(ErrorComponent)