import { FC } from 'react'
import { Page, MainContent, Content } from './styled-components'
import { ThemeProvider } from 'styled-components'
import themes from 'themes'
import { RootState } from 'data/store'
import { connect } from 'react-redux'
import { PageProps } from './types'
import { AccountsSelect } from 'components/common'

const mapStateToProps = ({
  drop: { step, theme },
  user: { address, accounts }
}: RootState) => ({
  step,
  address,
  accounts,
  theme
})
type ReduxType = ReturnType<typeof mapStateToProps>

const PageComponent: FC<PageProps & ReduxType> = ({
  children,
  theme,
  step
}) => {
  const currentTheme = theme === 'light' ? themes.light : themes.dark
  const hideAccounts = step === 'already_claimed' || step === 'claiming_finished'
  return (
    <ThemeProvider theme={currentTheme}>
      <Page>
        <MainContent>
          <Content>
            {children}              
            {!hideAccounts && <AccountsSelect disabled={step === 'claiming_process'}/>}
          </Content>
        </MainContent>
      </Page>
    </ThemeProvider>
  )
}


export default connect(mapStateToProps)(PageComponent)
