import React from 'react'
import CoverPage from '../cover-page'

const CampaignFinished = () => {
  return <CoverPage
    title='This party’s over'
    subtitle='Everything has been claimed…'
  />
}

export default CampaignFinished