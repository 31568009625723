import { FC } from 'react'
import { RootState } from 'data/store'
import { connect } from 'react-redux'
import {
  Container,
  TokenImage,
  TokenName,
  TokenTitle,
  TokenId,
  NoteStyled,
  Text,
  SetAddressContainer,
  SetAddressLink,
  MainTitle,
  ButtonStyled,
  Buttons,
  LedgerIcon
} from './styled-components'
import { defineApplicationUrl, getHashVariables, defineDeeplinkUrl } from 'helpers'
import { TDropStep } from 'types'
import Icons from'icons'
import * as dropActions from 'data/store/reducers/drop/actions'
import { Dispatch } from 'redux'
import { DropActions } from 'data/store/reducers/drop/types'

const mapStateToProps = ({
  user: { chainId },
  drop: { chainId: dropChainId, tokenId, isManual },
  token: { image, name }
}: RootState) => ({
  chainId,
  dropChainId,
  image,
  name,
  tokenId,
  isManual
})

const mapDispatcherToProps = (dispatch: Dispatch<DropActions>) => {
  return {
      stepStep: (step: TDropStep) => dispatch(dropActions.setStep(step))
  }
}

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps> 

const SetConnector: FC<ReduxType> = ({
  image,
  name,
  tokenId,
  isManual,
  stepStep
}) => {

  return <Container>
    <LedgerIcon>
      <Icons.LedgerLogo />
    </LedgerIcon>
    
    {image && <TokenImage src={image} alt={name} />}
    <TokenName>
      <TokenTitle>{name}</TokenTitle>
      {false && tokenId ? <TokenId>#{tokenId}</TokenId> : ''}
    </TokenName>

    <MainTitle>Claim your free NFT!</MainTitle>
    <Buttons>
      <ButtonStyled href={
        defineDeeplinkUrl({
          linkParams: btoa(window.location.hash)
        })
      }>
        Open Ledger Live
      </ButtonStyled>

      <ButtonStyled appearance='inverted' href={defineApplicationUrl()} target="_blank">
        Install Ledger Live
      </ButtonStyled>
    </Buttons>
    
    {!isManual && <SetAddressContainer>
      <Text>
        To receive your NFT manually
      </Text>
      <SetAddressLink
        onClick={() => {
          stepStep('set_address')
        }}
      >
        Click here
      </SetAddressLink>
    </SetAddressContainer>}
    
    <NoteStyled
      type='attention'
      text='We encourage you to download Ledger Live in order to securely receive your NFT'
    />
  </Container>
}

export default connect(mapStateToProps, mapDispatcherToProps)(SetConnector)