import styled, { css } from 'styled-components'
import { Button, Note } from 'components/common'

export const MainTitle = styled.h2`
  margin: 0 0 24px;
  color: ${props => props.theme.primaryTextColor};
  font-size: 24px;
`

export const LedgerIcon = styled.div`
  margin-bottom: 34px;
  ${props => css`
    svg path {
      fill: ${props.theme.secondaryBorderColor};
    }
  `}
`

export const MainIcon = styled.img`
  margin-bottom: 64px;
  max-width: 95px;
`

export const TokenImage = styled.img`
  width: 185px;
  height: 185px;
  object-fit: cover;
  border-radius: 8px;
  background: ${props => props.theme.widgetColor};
  margin-bottom: 24px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 340px;
`

export const TokenName = styled.div`
  font-size: 14px;
  color: ${props => props.theme.additionalTextColor};
  margin: 0 0 30px;
  display: flex;
  max-width: 340px;
`

export const TokenId = styled.p`
  margin: 0 0 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const TokenTitle = styled.div`
  white-space: pre;
  margin-right: 10px;
`

export const SetAddressContainer = styled.div`
  margin-bottom: 24px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Text = styled.p`
  color: ${props => props.theme.additionalTextColor};
  margin: 0 0 6px;
`

export const SetAddressLink = styled.div`
  color: ${props => props.theme.linkColor};
  text-decoration: underline;
  cursor: pointer;
`

export const ButtonStyled = styled(Button)`
  width: 100%;
  margin-bottom: 12px;
`

export const Buttons = styled.div`
  margin-bottom: 12px;
  width: 100%;
`

export const NoteStyled = styled(Note)`
  margin-bottom: 100px;
`