import styled, { css } from 'styled-components'
import { Select, Input } from 'components/common'
import { TAccountItem, TProps } from './types'

export const SelectStyled = styled(Select)``

export const Container = styled.div`
  background: ${props => props.theme.blankColor};
  display: flex;
  align-items: center;
  padding: 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  height: 57px;
  border-top: 1px solid ${props => props.theme.additionalBorderColor}; 
`

export const CurrentAccount = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  ${props => props.onClick && css`
    cursor: pointer;
  `}
`

export const InputStyled = styled(Input)`
  margin-bottom: 20px;
  input {
    border-radius: 48px;
  }
`

export const Account = styled.h3`
  height: 54px;
  display: flex;
  cursor: pointer;
  align-items: center;
`

export const AccountLogo = styled.div<TAccountItem>`
  width: 24px;
  height: 24px;
  min-width: 24px;
  border-radius: 24px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    max-width: 14px;
    width: 100%;
  }

  ${props => props.chain === 'ethereum' && css`
    background: ${props.theme.ethereumLogoColor};
  `}

  ${props => props.chain === 'polygon' && css`
    background: ${props.theme.widgetColor};
  `}
`

export const AccountName = styled.h4`
  font-weight: 700;
  font-size: 14px;
  color: ${props => props.theme.primaryTextColor};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 30px;
`

export const AccountBalance = styled.p`
  font-size: 13px;
  margin: 0 0 0 auto;
  font-weight: 400;
  color: ${props => props.theme.extraTextColor};
`

export const CurrentAccountChange = styled.p<TProps>`
  padding: 0;
  margin: 0 0 0 auto;
  color: ${props => props.theme.primaryTextColor};
  white-space: nowrap;
  cursor: pointer;
  font-size: 14px;

  ${props => props.disabled && css`
    cursor: not-allowed;
    color: ${props.theme.disabledTextColor};
  `}
`