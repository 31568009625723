import styled from 'styled-components'

export const Content = styled.div`
  padding: 24px 16px;
  text-align: center;
  background: ${props => props.theme.noteAttentionBgColor};
  border-radius: 8px;
`

export const Title = styled.h2`
  color: ${props => props.theme.additionalTextColor};
  margin: 0 0 8px;
  font-size: 13px;

`
export const Description = styled.p`
  color: ${props => props.theme.primaryTextColor};
  margin: 0 0 24px;
  font-size: 21px;
`

export const ApplicationStore = styled.img`
  max-width: 132px;
`

export const LedgerIcon = styled.div`

`

export const ApplicationStoreLink = styled.a`
  
`