import { FC, useEffect, useState } from 'react'
import {
  Title,
  ScreenButton,
  Container,
  Subtitle,
  TokenImageContainer,
  NoteStyled
} from './styled-components'
import { RootState, IAppDispatch } from 'data/store'
import { connect } from 'react-redux'
import { Loader } from 'components/common'
import { DropActions } from 'data/store/reducers/drop/types'
import { TokenActions } from 'data/store/reducers/token/types'
import * as dropAsyncActions from 'data/store/reducers/drop/async-actions'
import { Dispatch } from 'redux'
import * as dropActions from 'data/store/reducers/drop/actions'
import { TDropStep } from 'types'
import * as userAsyncActions from 'data/store/reducers/user/async-actions'

const mapStateToProps = ({
  token: { name, image },
  user: { address },
  drop: { tokenId, amount, type, isManual, loading, autoClaim }
}: RootState) => ({
  name, image, type, tokenId, amount, isManual, loading, autoClaim, address
})

const mapDispatcherToProps = (dispatch: Dispatch<DropActions> & Dispatch<TokenActions> & IAppDispatch) => {
  return {
    claimERC1155: () => dispatch(
      dropAsyncActions.claimERC1155()
    ),
    claimERC721: () => dispatch(
      dropAsyncActions.claimERC721()
    ),
    claimERC20: () => dispatch(
      dropAsyncActions.claimERC20()
    ),
    stepStep: (step: TDropStep) => dispatch(dropActions.setStep(step)),
    selectAddress: () => dispatch(
      userAsyncActions.selectAccount()
    )
  }
}

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps> 


const InitialScreen: FC<ReduxType> = ({
  name,
  type,
  tokenId,
  amount,
  image,
  claimERC1155,
  claimERC721,
  claimERC20,
  isManual,
  loading,
  autoClaim,
  address,
  selectAddress
}) => {

  const [ initialized, setInitialized ] = useState<boolean>(false)

  const defineButton = () => {
    if (!address) {
      return <ScreenButton
        title='Choose Account'
        onClick={selectAddress}
      />
    }
    return <ScreenButton
      disabled={
        (type === 'erc1155' && (!tokenId || !amount)) ||
        (type === 'erc721' && (!tokenId)) ||
        (type === 'erc20' && (!amount)) ||
        loading
      }
      loading={loading}
      appearance={loading ? 'inverted' : 'default'}
      title='Add to my collection'
      onClick={() => {
        if (!address) {
          alert('No account selected')
          return setInitialized(true)
        }
        if (type === 'erc1155') {
          return claimERC1155()
        }
        if (type === 'erc721') {
          return claimERC721()
        }
        if (type === 'erc20') {
          return claimERC20()
        }
      }}
    />
  }

  useEffect(() => {
    if (autoClaim) {
      if (address) {
        if (type === 'erc1155') {
          claimERC1155()
        } else if (type === 'erc721') {
          claimERC721()
        } else if (type === 'erc20') {
          claimERC20()
        }
      } else {
        alert('No account selected')
        setInitialized(true)
      }
    } else {
      setInitialized(true)
    }
  }, [])

  if (!initialized) {
    return <Container>
      <Loader />
    </Container>
  }

  return <Container> 
    <Title>{name}</Title>
    {false && tokenId && <Subtitle>#{tokenId}</Subtitle>}
    {!isManual && <NoteStyled type='default' text='Here is a preview of the NFT you’re about to receive. All fees will be handled by Ledger.' />}
    {image && <TokenImageContainer src={image} alt={name} />}
    {defineButton()}
  </Container>
}

export default connect(mapStateToProps, mapDispatcherToProps)(InitialScreen)