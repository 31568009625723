import React, { FC } from 'react'
import { Image, Title, ButtonStyled, Subtitle } from './styled-components'
import ErrorImageBlack from 'images/error-black.png'
import ErrorImageWhite from 'images/error-white.png'
import { getHashVariables } from 'helpers'

const ErrorComponent: FC = () => {
  const { theme } = getHashVariables(window.location.href)
  const errorImage = theme === 'light' ? ErrorImageBlack : ErrorImageWhite

  return <>
    <Image src={errorImage} />
    <Title>Transaction failed</Title>
    <Subtitle>Please contact ledger support</Subtitle>
    <ButtonStyled
      href='ledgerlive://settings/help'
      title='Support'
      target='_blank'
    />
  </>
}

export default ErrorComponent